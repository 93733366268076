import React from "react";

const Message = ({ message }) => {
	return <div>
        <span style={{marginRight: 10}}>{message.userName}:</span>
        <span>{message.text}</span>
    </div>;
};

export default Message;
