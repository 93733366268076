import React, { useContext, useState } from "react";
import { Context } from "./";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Loader from "./components/Loader";
import firebase from "firebase";
import Message from "./components/Message";

const App = () => {
	const { firestore } = useContext(Context);
	const [newMessage, setNewMessage] = React.useState("");
	const myFormRef = React.useRef();
	const [name, setName] = React.useState(localStorage.getItem("name") || "");

	const [messages, loading] = useCollectionData(
		firestore.collection("messages").orderBy("createdAt"),
		{
			idField: "id",
		}
	);

	const textareaKeyPressedHandler = (e) => {
		if(e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault();
			sendMessage(e);
		}
	}
	
	const sendMessage = async (e) => {
		e.preventDefault();
		firestore.collection("messages").add({
			text: newMessage,
			userName: name,
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		});
		setNewMessage("");
	};

	const changeNameHandler = (e) => {
		localStorage.setItem("name", e.target.value);
		setName(e.target.value);
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<div>
			<div
				style={{
					marginBottom: 30,
					border: "1px solid black",
					maxHeight: 600,
					maxWidth: "100%",
					overflowY: "auto",
					padding: "5px 20px",
				}}
			>
				{messages.map((message) => (
					<Message key={message.id} message={message} />
				))}
			</div>
			<form
				style={{ display: "flex", alignItems: "flex-end" }}
				onSubmit={sendMessage}
				ref={myFormRef}
			>
				<div
					style={{ display: "flex", flexDirection: "column", marginRight: 20 }}
				>
					<input
						value={name}
						style={{ marginBottom: 15 }}
						onChange={changeNameHandler}
						placeholder="Имя отправителя"
					/>
					<textarea
						value={newMessage}
						onChange={(e) => setNewMessage(e.target.value)}
						placeholder="Текст сообщения"
						onKeyDown={textareaKeyPressedHandler}
					/>
				</div>
				<button type="submit">Отправить</button>
			</form>
		</div>
	);
};

export default App;
